@import "./mixin";
$prim_white: #efefef;
$prim_gray: #a2a2a2;
$prim_yellow: #ffff00;
$prim_blue: #1e1e5d;
$title: 4.8rem;
$subtitle: 1.4rem;
$paragraph: 0.95rem;
$titleFont: "Cormorant Garamond", serif;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  font-size: $paragraph;
  color: $prim_white;
}

html,
body {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  @include break(xl, xxl) {
    font-size: 19px;
  }
  @include break(md) {
    font-size: 13px;
  }
  @include break(sm) {
    font-size: 12px;
  }
  @include break(xs) {
    font-size: 11px;
  }
}
body {
  opacity: 0;
  background-color: rgba($color: #6183dc, $alpha: 0);
  animation: onPageLoad 0.8s ease-in-out 1;
  animation-fill-mode: forwards;
}

@keyframes onPageLoad {
  100% {
    opacity: 1;
    background-color: rgba($color: #6183dc, $alpha: 1);
  }
}

//-------------------------------------------- Header --------------------------------------------//
.header_container {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;

  .nav_container {
    width: 100%;
    height: 70px;
    padding: 0px 50px;
    top: 0px;
    position: fixed;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    transition-duration: 0.5s;

    @include break(xs) {
      justify-content: center;
      padding: 0px 0px;
      height: 50px;
    }
    &.isOverHero {
      background-color: $prim_blue;
      box-shadow: 0px 10px 15px -5px rgba($color: #020166, $alpha: 0.5);
    }
    &.nav_hidden {
      top: -80px;
    }
    .nav_logo {
      width: 45px;
      height: 45px;
      background: url(./components/header/images/logo.svg) no-repeat;
      background-position: center;
      position: relative;
      transform: translateX(-6px);
      @include break(xs) {
        display: none;
      }
      .nav_logo_link {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }

    ul {
      display: flex;
      list-style: none;
      @include break(xs) {
        width: 100%;
        justify-content: space-around;
      }
      .link_li {
        display: inline-block;
        margin-left: 1.8rem;
        @include break(xs) {
          margin: 0;
        }
        a {
          font-size: $paragraph;
          font-weight: bold;
          color: $prim_white;
          text-decoration: none;
          transition-duration: 0.4s;
          @include break(xs) {
            font-size: 1.1rem;
          }
        }
        .link_li_a_hovered {
          color: $prim_yellow;
        }
      }
      .link_decoration {
        width: 1px;
        height: 12px;
        transform: rotate(20deg);
        display: inline-block;
        margin-left: 1.8rem;
        background-color: $prim_white;
        @include break(sm) {
          display: none;
        }
      }
    }
  }

  .content_container {
    position: absolute;
    top: 50%;
    color: $prim_white;
    display: flex;
    flex-flow: column;
    margin-left: 50px;
    opacity: 0;
    height: 500px;
    width: 470px;
    border: $prim_white solid 1px;
    animation: heroRec 1.5s 1s ease-in-out;
    animation-fill-mode: forwards;
    @keyframes heroRec {
      0% {
        height: 0px;
        width: 0px;
        opacity: 0;
        transform: translate(235px, 0%);
      }
      50% {
        height: 2px;
        width: 470px;
        opacity: 1;
        transform: translate(0px, 0%);
      }
      100% {
        height: 500px;
        width: 470px;
        opacity: 1;
        transform: translateX(0px) translateY(-50%);
      }
    }
    @include break(xl, xxl) {
      height: 600px;
      width: 600px;
      @keyframes heroRec {
        0% {
          height: 0px;
          width: 0px;
          opacity: 0;
          transform: translate(235px, 0%);
        }
        50% {
          height: 2px;
          width: 600px;
          transform: translate(0px, 0%);
        }
        100% {
          height: 600px;
          width: 600px;
          opacity: 100;
          transform: translateX(0px) translateY(-50%);
        }
      }
    }
    @include break(md) {
      height: 450px;
      width: 420px;
      @keyframes heroRec {
        0% {
          height: 0px;
          width: 0px;
          opacity: 0;
          transform: translate(235px, 0%);
        }
        50% {
          height: 2px;
          width: 420px;
          transform: translate(0px, 0%);
        }
        100% {
          height: 450px;
          width: 420px;
          opacity: 100;
          transform: translateX(0px) translateY(-50%);
        }
      }
    }
    @include break(sm) {
      margin-left: inherit;
      left: 50%;
      height: 455px;
      width: 330px;
      @keyframes heroRec {
        0% {
          height: 0px;
          width: 0px;
          opacity: 0;
          transform: translateY(0%) translateX(-50%);
        }
        50% {
          height: 2px;
          width: 330px;
          transform: translateY(0%) translateX(-50%);
        }
        100% {
          height: 455px;
          width: 330px;
          opacity: 100;
          transform: translateY(-50%) translateX(-50%);
        }
      }
    }
    @include break(xxs) {
      height: 425px;
      width: 250px;
      @keyframes heroRec {
        0% {
          height: 0px;
          width: 0px;
          opacity: 0;
          transform: translateY(0%) translateX(-50%);
        }
        50% {
          height: 2px;
          width: 250px;
          transform: translateY(0%) translateX(-50%);
        }
        100% {
          height: 425px;
          width: 250px;
          opacity: 100;
          transform: translateY(-50%) translateX(-50%);
        }
      }
    }
    .content_mask {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      overflow: hidden;
      animation: addPadding 0.8s 1.7s;
      animation-fill-mode: forwards;
      @keyframes addPadding {
        0% {
          padding: 0px;
        }
        100% {
          padding: 13px;
        }
      }

      .content_subtitle {
        font-size: $subtitle;
        font-weight: 400;
      }
      .content_myName {
        font-family: $titleFont;
        font-weight: 400;
        font-size: $title;
        line-height: 4.5rem;
        @include break(sm) {
          font-size: 4rem;
        }
        @include break(xxs) {
          font-size: 3rem;
          line-height: 3rem;
        }
      }
      .content_decoration {
        width: 100%;
        height: 2px;
        margin-top: 10px;
        background-color: $prim_yellow;
      }
      .content_about {
        margin-top: 10px;
        font-weight: 400;
        line-height: 1.7rem;
      }
      .content_specialties {
        margin-top: 20px;
        font-family: $titleFont;
        font-size: $paragraph;
        letter-spacing: 1.5px;
      }
      .content_specialties_logos {
        margin-top: 5px;
        padding: 0px 10px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        .content_specialties_logos_react {
          width: 50px;
          @include break(xl, xxl) {
            width: 55px;
          }
          @include break(sm) {
            width: 40px;
          }
          @include break(xs) {
            width: 35px;
          }
        }
        .content_specialties_logos_js {
          width: 45px;
          @include break(xl, xxl) {
            width: 50px;
          }
          @include break(sm) {
            width: 35px;
          }
          @include break(xs) {
            width: 30px;
          }
        }
        .content_specialties_logos_html {
          width: 40px;
          @include break(xl, xxl) {
            width: 45px;
          }
          @include break(sm) {
            width: 30px;
          }
          @include break(xs) {
            width: 25px;
          }
        }
        .content_specialties_logos_css {
          width: 40px;
          @include break(xl, xxl) {
            width: 45px;
          }
          @include break(sm) {
            width: 30px;
          }
          @include break(xs) {
            width: 25px;
          }
        }
      }
      .content_more {
        margin-top: 20px;
        font-family: $titleFont;
        font-size: $paragraph;
        letter-spacing: 1.5px;
      }
      .content_more_skills {
        font-weight: 300;
        line-height: 1.7rem;
      }
    }
  }

  .sea {
    background: url(./components/header/images/parallax_sea.png) no-repeat;
  }
  .me {
    background: url(./components/header/images/parallax_me.png) no-repeat;
  }
  .header_bg {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    position: absolute;
    z-index: -1;
  }

  .sidebar_container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(110px);
    width: 150px;
    height: 215px;
    position: absolute;
    display: flex;
    flex-flow: row;
    align-items: center;
    transition-duration: 0.5s;
    animation: sideBarLoad 1s ease-in-out 1.8s;
    animation-fill-mode: forwards;
    @include break(md) {
      transform: translateY(-50%) translateX(110px);
    }
    @keyframes sideBarLoad {
      75% {
        transform: translateY(-50%) translateX(-10px);
      }
      85% {
        transform: translateY(-50%) translateX(8px);
      }
      100% {
        transform: translateY(-50%) translateX(0px);
      }
    }
    @include break(xs) {
      width: 100px;
      @keyframes sideBarLoad {
        75% {
          transform: translateY(-50%) translateX(-60px);
        }
        85% {
          transform: translateY(-50%) translateX(-40px);
        }
        100% {
          transform: translateY(-50%) translateX(-50px);
        }
      }
    }
    .sidebar_arrow_container {
      width: 32px;
      height: 115px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transform: scale(1);
      transition-duration: 0.5s;
    }
    .sidebar_logos_container {
      display: flex;
      flex-flow: column;
      margin-left: 15px;
      .sidebar_logos_logo {
        margin: 13px 0px;
      }
    }

    .svg_container {
      position: absolute;
      z-index: -1;
      path {
        stroke: $prim_white;
        stroke-width: 1px;
        transition-duration: 0.5s;
      }
      .svg_fill_in {
        fill: rgba(24, 24, 188, 0);
      }
      .svg_fill_out {
        fill: rgba(24, 24, 188, 0.8);
      }
    }
  }

  .header_bottom_mask {
    width: 100%;
    height: 200px;
    position: absolute;
    bottom: 0;
    background: linear-gradient(to top, rgb(30, 30, 93), rgba(30, 30, 93, 0));
  }
}

//-------------------------------------------- Block_for making extra space --------------------------------------------//

.app_block {
  width: 100%;
  height: 15vh;
  background-color: $prim_blue;
}

//-------------------------------------------- Main --------------------------------------------//

.main_container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  position: relative;
  background: linear-gradient(to right bottom, #1e1e5a 70%, #15338d);
  @include break(md) {
    flex-wrap: wrap;
  }

  .sliderPicture_container {
    width: 100%;
    min-height: 80vh;
    position: relative;
    overflow: hidden;
    opacity: 0;
    transition-duration: 1.5s;
    &.animateIn {
      opacity: 1;
    }
    @include break(md) {
      min-width: 100vw;
      min-height: 50vh;
    }
    .sliderPicture_rowOfPictures {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      transition: 0.9s ease;
      .sliderPicture_picture {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: 0.8s ease;
        opacity: 0.2;
        &.currentSlide {
          transform: scale(1.1);
          opacity: 0.8;
        }
      }
    }

    .sliderPicture_mask {
      position: absolute;
      top: 0px;
      &.top {
        left: 0px;
        width: 100%;
        height: 8rem;
        background: linear-gradient(
          to bottom,
          rgb(30, 30, 93),
          rgba(30, 30, 93, 0)
        );
      }
      &.right {
        right: 0px;
        width: 8rem;
        height: 100%;
        background: linear-gradient(
          to left,
          rgb(30, 30, 93),
          rgba(30, 30, 93, 0)
        );
        @include break(md) {
          top: unset;
          bottom: 0px;
          height: 8rem;
          width: 100%;
          background: linear-gradient(
            to top,
            rgb(30, 30, 93),
            rgba(30, 30, 93, 0)
          );
        }
      }
    }
  }

  .sliderContent_container {
    width: 100%;
    min-height: 80vh;
    padding: 50px;
    position: relative;
    opacity: 0;
    transition-duration: 1s;

    &.animateIn {
      opacity: 1;
    }
    @include break(md) {
      padding: unset;
      min-width: 100vw;
      min-height: 50vh;
    }
    .sliderContent_mapDiv_container {
      @include break(md) {
        position: relative;
        padding: 30px 50px 120px 50px;
        width: 100%;
        height: 100%;
      }

      .sliderContent_titleContainer {
        transform: translateX(0px);
        opacity: 0;
        transition-duration: 1s;
        &.animateIn {
          opacity: 1;
          transform: translateX(-100px);
        }
        @include break(md) {
          &.animateIn {
            transition-delay: 0.2s;
            opacity: 1;
            transform: translateX(0px);
          }
        }
        .sliderContent_subtitle {
          font-size: $subtitle;
          font-style: italic;
          font-weight: 300;
          color: #8da5e2;
          margin-bottom: 12px;
        }
        .sliderContent_title {
          color: $prim_yellow;
          font-size: $title;
          font-family: $titleFont;
          font-weight: 400;
          line-height: 4.3rem;
          @include break(sm) {
            font-size: 4rem;
          }
          @include break(xxs) {
            font-size: 3.3rem;
          }
        }
      }
      .sliderContent_decoration_yellow {
        width: 2.5rem;
        height: 3px;
        margin: 1.3rem 0px;
        background-color: $prim_yellow;
      }
      .sliderContent_description {
        font-size: $paragraph;
        margin-top: 10px;
        font-weight: 400;
        text-align: justify;
        line-height: 2.2rem;
        opacity: 0;
        transform: translateY(50px);
        transition-duration: 1s;
        transition-delay: 0.2s;
        &.animateIn {
          opacity: 1;
          transform: translateY(0px);
        }
      }
      .sliderContent_decoration_white {
        width: 100%;
        height: 1px;
        margin-top: 2.5rem;
        background-color: #8da5e2;
      }
      .sliderContent_roles {
        font-size: $paragraph;
        font-weight: 300;
        margin-top: 1.1rem;
        text-align: right;
        color: #8da5e2;
      }
      .sliderContent_btn {
        width: 10rem;
        height: 2.5rem;
        margin-top: 2.5rem;
        letter-spacing: 1.5px;
        border: solid 1px $prim_white;
        z-index: 1;
        a {
          display: block;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          font-weight: 400;
          font-family: $titleFont;
          font-size: $subtitle;
          transition: 0.5s;
          &:hover {
            background-color: $prim_yellow;
            color: $prim_blue;
          }
        }
        @include break(md) {
          position: absolute;
          left: 50%;
          bottom: 40px;
          transform: translateX(-50%);
        }
        @include break(xs) {
          bottom: 70px;
        }
      }
      .sliderContent_numberBG {
        width: 400px;
        height: 400px;
        bottom: 0px;
        right: 0px;
        opacity: 0.2;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: right;
        position: absolute;
      }
    }
  }
  svg {
    width: 4rem;
    height: 4rem;
    position: absolute;
    bottom: 40px;
    left: 50px;
    fill: rgba($color: #1e1e5d, $alpha: 0);
    transition: 0.2s ease-in-out;
    @include break(xs) {
      bottom: 20px;
    }
    &:hover {
      fill: rgba($color: #1e1e5d, $alpha: 1);
      transform: scale(1.2);
      opacity: 0.7;
    }
    &.next {
      left: unset;
      right: 50px;
      transform: rotate(180deg);
      &:hover {
        transform: scale(1.2) rotate(180deg);
      }
    }
  }
}

//-------------------------------------------- Gallery --------------------------------------------//
.gallery_wrapper {
  position: relative;
  .expand_gallery {
    position: absolute;
    width: 100%;
    height: 20vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($prim_white, $alpha: 0.7);
    .expandBtn {
      position: relative;
      font-family: $titleFont;
      color: $prim_blue;
      font-size: $subtitle;
      font-style: italic;
      cursor: pointer;
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0%;
        height: 2px;
        background-color: $prim_blue;
        transform-origin: center;

        transition: 0.5s;
      }
      &:hover:after {
        left: 0;
        width: 100%;
      }
    }
  }
  .gallery_container {
    width: 100%;
    height: 20vh;
    overflow: hidden;

    position: relative;
    padding: 50px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $prim_white;
    filter: blur(3px);
    transition: 1s;
    .gallery_fallback {
      font-size: $paragraph;
      color: $prim_gray;
      font-style: italic;
    }

    .filter_container {
      margin: 20px 0px 40px 0px;
      display: flex;
      width: 96%;
      flex-wrap: wrap;
      justify-content: center;

      .filter_btn {
        all: unset;
        text-align: center;
        margin: 5px;
        min-width: 90px;
        cursor: pointer;
        -webkit-text-fill-color: $prim_gray;
        color: $prim_gray;
        border: solid 1px $prim_gray;
        font-size: $paragraph;
        transition-duration: 0.5s;
        @include break(sm) {
          min-width: 70px;
        }

        &.selected {
          background-color: $prim_gray;
          border: solid 1px $prim_gray;
          -webkit-text-fill-color: $prim_white;
          color: $prim_white;
        }
        &:hover {
          background-color: $prim_gray;
          border: solid 1px $prim_gray;
          -webkit-text-fill-color: $prim_white;
          color: $prim_white;
        }
      }
    }

    .card_cards_container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .card_singalCard_container {
        flex-basis: 250px;
        height: 250px;
        margin: 20px;
        flex-grow: 1;
        overflow: hidden;
        transform: translateY(20px);
        opacity: 0;
        &.animateIn {
          transition-duration: 1s;
          transform: translateY(0px);
          opacity: 1;
        }
        .card_singalCard_img {
          width: 100%;
          height: 100%;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          cursor: pointer;
          transition-duration: 0.5s;
        }
        &:hover .card_singalCard_img {
          transition-duration: 0.5s;
          transform: scale(1.1);
        }
      }
    }

    .modal_container {
      position: fixed;
      z-index: 1;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      padding: 50px 0px;
      overflow: scroll;
      background-color: rgba(0, 0, 0, 0.4);

      .modal_content {
        width: 80%;
        min-height: 100vh;
        margin: auto;
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 10px 20px 0px 20px;
        background-color: $prim_white;
        box-shadow: 0px 0px 30px -13px rgba($color: #0f1dec, $alpha: 0.7);
        .modal_cross_container {
          position: absolute;
          width: 1.8rem;
          height: 1.8rem;
          top: 20px;
          right: 20px;
          color: $prim_gray;
          cursor: pointer;
          .modal_crossBody {
            width: 120%;
            height: 0.2rem;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            background-color: $prim_gray;
            border-radius: 50px;
            transition-duration: 0.3s;
            transition-timing-function: ease-in-out;
            &.top {
              transition-delay: 0.05s;
              transform: translateX(-50%) translateY(-50%) rotate(45deg);
            }
            &.bottom {
              transform: translateX(-50%) translateY(-50%) rotate(-45deg);
            }
          }
          &:hover .top {
            background-color: $prim_blue;
            transform: translateX(-50%) translateY(-50%) rotate(135deg);
          }
          &:hover .bottom {
            background-color: $prim_blue;
            transform: translateX(-50%) translateY(-50%) rotate(45deg);
          }
        }

        .modal_description_container {
          margin: 30px 0px 10px 0px;
          width: 90%;
          line-height: 1.8rem;
          h3 {
            color: $prim_blue;
            font-weight: 400;
            font-size: $paragraph;
          }
        }

        .modal_img {
          width: 100%;
          height: 100%;
          margin: 20px 0px;
        }
      }
    }
  }
  .expand {
    height: 100%;
    min-height: 100vh;
    filter: none;
  }
}
//-------------------------------------------- Contact --------------------------------------------//

#contact_container {
  width: 100%;
  min-height: 55vh;
  padding: 20px 10px;
  background-color: $prim_blue;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .contact-form {
    width: 50%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: $prim_white;
    box-shadow: 0px -10px 10px -5px rgba($color: #020166, $alpha: 0.5);
    @include break(xs) {
      width: 80%;
    }
    @include break(md) {
      width: 70%;
    }
    h1 {
      color: $prim_blue;
      font-size: $subtitle;
      font-family: $titleFont;
    }
    input,
    textarea {
      all: unset;
      padding: 0px 0px 2px 0px;
      margin: 5px 0px;
      color: $prim_blue;
      border-bottom: solid 1px $prim_gray;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-text-fill-color: $prim_blue !important;
      -webkit-box-shadow: 0 0 0 30px $prim_white inset !important;
      box-shadow: 0 0 0 30px $prim_white inset !important;
    }
    .send_btn {
      -moz-appearance: none;
      -webkit-appearance: none;
      margin: 1rem auto 0 auto;
      width: 10rem;
      height: 2rem;
      letter-spacing: 1.5px;
      display: inline-block;
      font-weight: 400;
      font-family: $titleFont;
      font-size: $subtitle;
      color: $prim_blue;
      border: solid 1px $prim_blue;
      cursor: pointer;
      transition: 0.5s;
      &:hover {
        background-color: $prim_yellow;
        color: $prim_blue;
      }
    }
  }
}

//-------------------------------------------- Footer --------------------------------------------//

.footer_container {
  width: 100%;
  min-height: 50px;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: $prim_blue;
  box-shadow: 0px -10px 15px -5px rgba($color: #020166, $alpha: 0.5);
  @include break(lg) {
    flex-direction: column;
    padding: 10px;
  }
  .footer_icons_container {
    display: flex;
    width: 150px;
    justify-content: space-between;
    align-items: center;
    @include break(lg) {
      margin-bottom: 5px;
    }
  }
  .footer_copyright {
    position: absolute;
    left: 50px;
    font-weight: 100;
    font-size: 11px;
    color: #8da5e2;
    @include break(lg) {
      left: unset;
      position: relative;
    }
  }
}

// $breakpoints: (
//   xxs: 375px,
//   xs: 440px,
//   sm: 576px,
//   md: 768px,
//   lg: 1080px,
//   xl: 1440px,
//   xxl: 1920px,
// );
@mixin break($size, $isMax: false) {
    $breakpoints: (
      // Extra extra small screen / phone
      xxs: 375px,
      // Extra small screen / phone
      xs: 440px,
      // Small screen / phone
      sm: 576px,
      // Medium screen / tablet
      md: 768px,
      // Medium large screen / desktop narrow
      // mdlg: 968px,
      // Large screen / desktop
      lg: 1080px,
      // Extra large screen / wide desktop
      xl: 1440px,
      xxl: 1920px,
    );
    @if (map-has-key($breakpoints, $size)) {
      @if ($isMax) {
        @media (min-width: (map-get($breakpoints, $size) - 1px)) {
          @content;
        }
      } @else {
        @media (max-width: map-get($breakpoints, $size)) {
          @content;
        }
      }
    } @else {
      @warn "$size doesn't exist in $breakpoints.";
    }
  }